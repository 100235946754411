import React, { useState, useEffect } from 'react';
import axios from 'axios';

const PlayerLeaderboard = () => {
    const [playerStats, setPlayerStats] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: 'minecraft:total_world_time', direction: 'descending' });

    useEffect(() => {
        const fetchPlayerStats = async () => {
            try {
                const response = await axios.get('/api/player-stats');
                setPlayerStats(response.data);
            } catch (error) {
                console.error('Error fetching player stats:', error);
            }
        };

        fetchPlayerStats();
    }, []);

    const convertTicksToTime = (ticks) => {
        const totalMinutes = Math.floor(ticks / 20 / 60);
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        return `${hours}h ${minutes}m`;
    };

    const sortedPlayerStats = React.useMemo(() => {
        let sortableStats = Object.entries(playerStats).map(([uuid, stats]) => ({ uuid, ...stats.stats['minecraft:custom'] }));
        if (sortConfig !== null) {
            sortableStats.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableStats;
    }, [playerStats, sortConfig]);

    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    return (
        <div className="table-container">
            <table>
                <thead>
                    <tr>
                        <th>Player UUID</th>
                        <th onClick={() => requestSort('minecraft:total_world_time')}>Total World Time</th>
                        <th onClick={() => requestSort('minecraft:mob_kills')}>Mob Kills</th>
                        <th onClick={() => requestSort('minecraft:deaths')}>Deaths</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedPlayerStats.map((player) => (
                        <tr key={player.uuid}>
                            <td>{player.uuid}</td>
                            <td>{player['minecraft:total_world_time'] ? convertTicksToTime(player['minecraft:total_world_time']) : 'N/A'}</td>
                            <td>{player['minecraft:mob_kills'] || 'N/A'}</td>
                            <td>{player['minecraft:deaths'] || 'N/A'}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default PlayerLeaderboard;