import React, { useState, useEffect } from 'react';
import axios from 'axios';

function ServerStatusComponent() {
    const [serverData, setServerData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchServerStatus = async () => {
            try {
                const response = await axios.get('https://api.mcsrvstat.us/3/104.193.179.86:25565');
                setServerData(response.data);
                //console.log('Server Status:', response.data);
            } catch (error) {
                //console.error('Error fetching server status:', error);
                setError('Error fetching server status');
            }
        };

        fetchServerStatus();
    }, []);

    return (
        <section className="server-status">
            <div className="server-status-container">


                {error ? (
                    <p className="error">{error}</p>
                ) : serverData ? (
                    <div>
                        <p><strong>Server Address</strong> play.moddedcraft.com</p>
                        <p><strong>Players Online:</strong> {serverData.players.online} / {serverData.players.max}</p>
                        {serverData.players.list && serverData.players.list.length > 0 && (
                            <div>
                                <p><strong>Online Players:</strong></p>
                                <ul>
                                    {serverData.players.list.map(player => (
                                        <li key={player.uuid}>{player.name}</li>
                                    ))}
                                </ul>
                            </div>
                        )}
                        <p><strong>Version:</strong> {serverData.version}</p>
                    </div>
                ) : (
                    <p>Loading...</p>
                )}
            </div>
        </section>
    );
}

export default ServerStatusComponent;