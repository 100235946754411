import React from 'react';
import WaxWalletLoginComponent from './WaxWalletLoginComponent';
import PlayerClaimsReportComponent from './PlayerClaimsReport';

const ClaimsPage = ({
    setMinecraftUUID,
    setUser,
    setCsrfToken,
    setMinecraftName,
    setUserData,
    setError,
    user,
    csrfToken,
    minecraftName,
    minecraftUUID,
    userData,
    error
}) => {
    return (
        <div>
            <WaxWalletLoginComponent setMinecraftUUID={setMinecraftUUID}
                setUser={setUser}
                setCsrfToken={setCsrfToken}
                setMinecraftName={setMinecraftName}
                setUserData={setUserData}
                setError={setError}
                user={user}
                csrfToken={csrfToken}
                minecraftName={minecraftName}
                userData={userData}
                error={error} />
            <PlayerClaimsReportComponent />
        </div>
    );
};

export default ClaimsPage;