import React, { useState, useEffect } from 'react';
import axios from 'axios';

const PlayerPersonalStatsComponent = ({ uuid, user }) => {
    const [playerStats, setPlayerStats] = useState(null);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchPlayerStats = async () => {
            try {
                //console.log('Fetching player stats for:', uuid);
                const response = await axios.get(`/api/player-stats/${uuid}`);
                //console.log('Player stats:', response.data);
                setPlayerStats(response.data);
            } catch (error) {
                //console.error('Error fetching player stats:', error);
                setError('Error fetching player stats.');
            }
        };

        if (uuid) {
            fetchPlayerStats();
        }
    }, [uuid]);

    return (

        <div className="table-container">
            {user && <h1>{user}'s Player Stats</h1>}
            {error && <p style={{ color: 'red' }}>{error}</p>}
            {playerStats ? (
                <table>
                    <thead>
                        <tr>
                            <th>Stat</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.entries(playerStats.stats).map(([category, stats]) => (
                            Object.entries(stats).map(([stat, value]) => (
                                <tr key={`${category}-${stat}`}>
                                    <td>{stat}</td>
                                    <td>{value}</td>
                                </tr>
                            ))
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>Loading...</p>
            )}
        </div>
    );
};

export default PlayerPersonalStatsComponent;