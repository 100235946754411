import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Buffer } from 'buffer'; // Import the Buffer polyfill
import { sessionKit } from './App';

import NavigationMenuComponent from './NavigationMenuComponent';


// Polyfill the global Buffer object
window.Buffer = Buffer;

function WaxWalletLoginComponent({ setMinecraftUUID,
    setUser,
    setCsrfToken,
    setMinecraftName,
    setUserData,
    setError,
    user,
    csrfToken,
    minecraftName,
    userData,
    error }) {

    useEffect(() => {
        // Fetch CSRF token once
        const fetchCsrfToken = async () => {
            try {
                const response = await axios.get('/api/csrf-token');
                setCsrfToken(response.data.csrfToken);
                // Restore session after fetching CSRF token
                restoreSession(response.data.csrfToken);
            } catch (error) {
                console.error('Error fetching CSRF token:', error);
            }
        };

        fetchCsrfToken();

        // Restore session if available
        const restoreSession = async (csrfToken) => {
            try {
                const session = await sessionKit.restore();
                if (session) {
                    const waxAddress = session.actor.toString();
                    setUser(waxAddress);
                    const response = await axios.get(`/api/get-user?wax_address=${waxAddress}`, {
                        headers: {
                            'CSRF-Token': csrfToken
                        }
                    });

                    setUserData(response.data);
                    setMinecraftUUID(response.data.minecraft_uuid);

                }
            } catch (error) {
                console.error('Error restoring session:', error);
            }
        };
    }, []);

    const login = async () => {
        try {

            const session = await sessionKit.login();
            const waxAddress = session.session.actor.toString();
            setUser(waxAddress);
            localStorage.setItem('waxUser', JSON.stringify(waxAddress));
            // Send WAX address to the server
            axios.post('/api/store-user', {
                wax_address: waxAddress
            }, {
                headers: {
                    'CSRF-Token': csrfToken
                }
            })
                .then(response => {
                    setUserData(response.data);
                    setMinecraftUUID(response.data.minecraft_uuid);
                })
                .catch(error => {
                    console.error('Error storing user data:', error);
                });
        } catch (error) {
            console.error('Login Failed:', error);
        }
    };


    const logOut = () => {
        setUser(null);
        setUserData(null);
        localStorage.removeItem('waxUser');
        sessionKit.logout()
        window.location.reload()
    };

    const updateMinecraftInfo = async () => {
        setError('');
        try {
            // Fetch player's UUID from the proxy endpoint
            const uuidResponse = await axios.get(`/api/minecraft-uuid/${minecraftName}`);
            const { id: minecraftId } = uuidResponse.data;
            setMinecraftUUID(minecraftId);
            axios.post('/api/update-minecraft-info', {
                wax_address: user,
                minecraft_name: minecraftName,
                minecraft_uuid: minecraftId
            }, {
                headers: {
                    'CSRF-Token': csrfToken
                }
            })
                .then(response => {
                    console.log('Minecraft info updated:', response.data);
                    setUserData(response.data);

                })
                .catch(error => {
                    console.error('Error updating Minecraft info:', error);
                    if (error.response && error.response.data.error) {
                        setError(error.response.data.error);
                    } else {
                        setError('Error updating Minecraft info.');
                    }
                });
        } catch (error) {
            console.error('Error fetching Minecraft ID:', error);
            setError('Error fetching Minecraft ID.');
        }
    };



    return (
        <div>
            <section className="waxWalletLogin">
                <div className="loginContainer">
                    {user ? (
                        <div id="loggedin">

                            <button onClick={logOut}>Log out</button>

                            <h4><span className="highlight-info">{user}</span></h4>

                        </div>
                    ) : (
                        <div>
                            <div className='loginButtonContainer'>
                                <button onClick={login} id="logout"> <img src="./full-wax-logo.png" alt="wax logo" /> Login</button>
                            </div>
                            {error && <p style={{ color: 'red' }}>{error}</p>}
                        </div>
                    )}
                </div>



                <div className="landingMessage">
                    <h3><span className="highlight-info">play.moddedcraft.com</span></h3>
                </div>


                <NavigationMenuComponent user={user} />

            </section>
            {user ? (
                <div className="updateMinecraftInfo">
                    {userData ? (
                        <div className="minecraftInfo">
                            {userData.minecraft_name && userData.minecraft_name !== '' ? (
                                <h4>Minecraft Name: <span className="highlight-info">{userData.minecraft_name}</span></h4>
                            ) : (
                                <h4>Link your Minecraft Account to be whitelisted</h4>
                            )}
                        </div>
                    ) : (
                        <p>Loading user data...</p>
                    )}
                    <div className="updateMinecraftInfoForm">
                        <input
                            type="text"
                            value={minecraftName}
                            onChange={(e) => setMinecraftName(e.target.value)}
                            placeholder="Minecraft name"
                        />
                        <button onClick={updateMinecraftInfo}>Update Minecraft Info</button>
                        {error && <p style={{ color: 'red' }}>{error}</p>}
                    </div>

                </div>
            ) : (

                <div className="landingMessage">
                    <h3>Sign in to get whitelisted on <span className="highlight-info">play.moddedcraft.com</span></h3>
                </div>


            )}</div>


    );
}
export default WaxWalletLoginComponent;