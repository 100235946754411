import React, { useState, useEffect } from 'react';
import axios from 'axios';

const WhitelistComponent = () => {
    const [whitelistData, setWhitelistData] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchWhitelistData = async () => {
            try {
                const response = await axios.get('/api/whitelist');
                console.log(response.data);
                setWhitelistData(response.data);
            } catch (error) {
                setError('Error fetching whitelist data');
            }
        };

        fetchWhitelistData();

    }, []);

    return (
        <div className="whiteListedPlayers">
            <h3>Whitelisted Players</h3>
            <h4>(refreshes every 30 minutes)</h4>
            {error ? (
                <p className="error">{error}</p>
            ) : whitelistData.length > 0 ? (
                <div>
                    <ul>
                        {whitelistData.map((player, index) => (
                            <li key={index}>
                                <p><strong>{player.name}</strong> </p>

                            </li>
                        ))}
                    </ul>
                </div>
            ) : (
                <p>Loading...</p>
            )}
        </div>
    );
};

export default WhitelistComponent;