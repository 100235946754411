import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import HomePage from './HomePage';
import StatsPage from './StatsPage';
import ClaimsPage from './ClaimsPage';
import { SessionKit, Chains } from '@wharfkit/session';
import { WebRenderer } from '@wharfkit/web-renderer';
import { WalletPluginAnchor } from '@wharfkit/wallet-plugin-anchor';
import { WalletPluginCloudWallet } from '@wharfkit/wallet-plugin-cloudwallet';
import { TransactPluginResourceProvider } from "@wharfkit/transact-plugin-resource-provider"


const webRenderer = new WebRenderer();
const chains = [Chains.WAX];
const anchor = new WalletPluginAnchor();
const wcw = new WalletPluginCloudWallet();

const sessionKit = new SessionKit({
  appName: 'moddedcraft',
  chains,
  ui: webRenderer,
  walletPlugins: [anchor, wcw] // Only using WalletPluginAnchor for SessionKit
},

  {
    transactPlugins: [new TransactPluginResourceProvider()],
  });
function App() {

  const [minecraftUUID, setMinecraftUUID] = useState('');
  const [user, setUser] = useState(null);
  const [csrfToken, setCsrfToken] = useState('');
  const [minecraftName, setMinecraftName] = useState('');
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState('');

  // useEffect(() => {
  //   // Check if user is already logged in
  //   const storedUser = localStorage.getItem('waxUser');
  //   if (storedUser) {
  //     setUser(JSON.parse(storedUser));
  //   }
  // }, []);

  return (
    <Router>
      <div className="App">
        <main>
          <div>

            <Routes>
              <Route path="/" element={<HomePage setMinecraftUUID={setMinecraftUUID}
                setUser={setUser}
                setCsrfToken={setCsrfToken}
                setMinecraftName={setMinecraftName}
                setUserData={setUserData}
                setError={setError}
                user={user}
                csrfToken={csrfToken}
                minecraftName={minecraftName}
                userData={userData}
                error={error}
                minecraftUUID={minecraftUUID} />} />
              <Route path="/stats" element={<StatsPage setMinecraftUUID={setMinecraftUUID}
                setUser={setUser}
                setCsrfToken={setCsrfToken}
                setMinecraftName={setMinecraftName}
                setUserData={setUserData}
                setError={setError}
                user={user}
                csrfToken={csrfToken}
                minecraftName={minecraftName}
                userData={userData}
                error={error}
                minecraftUUID={minecraftUUID} />} />
              <Route path="/claims" element={<ClaimsPage setMinecraftUUID={setMinecraftUUID}
                setUser={setUser}
                setCsrfToken={setCsrfToken}
                setMinecraftName={setMinecraftName}
                setUserData={setUserData}
                setError={setError}
                user={user}
                csrfToken={csrfToken}
                minecraftName={minecraftName}
                userData={userData}
                error={error}
                minecraftUUID={minecraftUUID} />} />
              {/* Add more routes as needed */}
            </Routes>
          </div>
        </main>
      </div>
    </Router>
  );
}

export { sessionKit };
export default App;