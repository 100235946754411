import React from 'react';
import WaxWalletLoginComponent from './WaxWalletLoginComponent';
import PlayerStatsComponent from './PlayerStatsComponent';
import PlayerTotalBlocksComponent from './PlayerTotalBlocksComponent';
import PlayerPersonalStatsComponent from './PlayerPersonalStatsComponent';
import PlayerLeaderboard from './PlayerLeaderboard';
const StatsPage = ({
    setMinecraftUUID,
    setUser,
    setCsrfToken,
    setMinecraftName,
    setUserData,
    setError,
    user,
    csrfToken,
    minecraftName,
    minecraftUUID,
    userData,
    error
}) => {
    return (
        <div>
            <WaxWalletLoginComponent setMinecraftUUID={setMinecraftUUID}
                setUser={setUser}
                setCsrfToken={setCsrfToken}
                setMinecraftName={setMinecraftName}
                setUserData={setUserData}
                setError={setError}
                user={user}
                csrfToken={csrfToken}
                minecraftName={minecraftName}
                userData={userData}
                error={error} />
            <h1>Leaderboard</h1>
            <PlayerLeaderboard />
            {/* <h1>Server Player Stats</h1>
            <PlayerStatsComponent /> */}
            <h1>Total Claimable Blocks</h1>
            <PlayerTotalBlocksComponent />
            {user &&
                <div>
                    <PlayerPersonalStatsComponent uuid={minecraftUUID} user={user} />
                </div>}
        </div>
    );
};

export default StatsPage;