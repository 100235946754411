import React, { useState, useEffect } from 'react';
import axios from 'axios';

const PlayerTotalBlocksComponent = () => {
    const [playerTotalBlocks, setPlayerTotalBlocks] = useState({});
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPlayerTotalBlocks = async () => {
            try {
                const response = await axios.get('/api/player-total-blocks');
                setPlayerTotalBlocks(response.data);
            } catch (error) {
                setError('Error fetching player total blocks');
            }
        };

        fetchPlayerTotalBlocks();
    }, []);

    return (
        <div className="table-container">
            <h3>Player Total Blocks</h3>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            {Object.keys(playerTotalBlocks).length > 0 ? (
                <table>
                    <thead>
                        <tr>
                            <th>Player</th>
                            <th>Total Number of Blocks</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.entries(playerTotalBlocks).map(([player, totalBlocks], index) => (
                            <tr key={index}>
                                <td>{player}</td>
                                <td>{totalBlocks}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>Loading...</p>
            )}
        </div>
    );
};

export default PlayerTotalBlocksComponent;