import React, { useState, useEffect } from 'react';
import axios from 'axios';

const calculateLandClaimArea = (lesserBoundary, greaterBoundary) => {
    const [world1, x1, , z1] = lesserBoundary.split(';');
    const [world2, x2, , z2] = greaterBoundary.split(';');

    if (world1 !== world2) {
        throw new Error('Both corners must be in the same world');
    }

    const dx = Math.abs(parseInt(x2) - parseInt(x1));
    const dz = Math.abs(parseInt(z2) - parseInt(z1));

    const area = dx * dz;
    return area;
};

const extractCoordinates = (boundary) => {
    const [, x, y, z] = boundary.split(';');
    return { x: parseInt(x), y: parseInt(y), z: parseInt(z) };
};

const PlayerClaimsReportComponent = () => {
    const [playerClaims, setPlayerClaims] = useState({});
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPlayerClaimsReport = async () => {
            try {
                const response = await axios.get('/api/player-claims-report');
                setPlayerClaims(response.data);
            } catch (error) {
                setError('Error fetching player claims report');
            }
        };

        fetchPlayerClaimsReport();
    }, []);

    return (
        <div>
            <h3>Player Claims Report</h3>
            <div className="table-container">
                {error && <p style={{ color: 'red' }}>{error}</p>}
                {Object.keys(playerClaims).length > 0 ? (
                    <table>
                        <thead>
                            <tr>
                                <th>Player</th>
                                <th>Claims</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(playerClaims).map((player, index) => (
                                <tr key={index}>
                                    <td>{player}</td>
                                    <td>
                                        <ul>
                                            {playerClaims[player].locations && playerClaims[player].locations.length > 0 ? (
                                                playerClaims[player].locations.map((location, locIndex) => {
                                                    const lesserCoords = extractCoordinates(location.lesserBoundary);
                                                    const greaterCoords = extractCoordinates(location.greaterBoundary);
                                                    const length = Math.abs(greaterCoords.x - lesserCoords.x);
                                                    const width = Math.abs(greaterCoords.z - lesserCoords.z);
                                                    const area = calculateLandClaimArea(location.lesserBoundary, location.greaterBoundary);
                                                    return (
                                                        <li key={locIndex}>

                                                            <p><strong>Location:</strong> {`x: ${lesserCoords.x}, y: ${lesserCoords.y}, z: ${lesserCoords.z}`}</p>
                                                            <p><strong>Length:</strong> {length}</p>
                                                            <p><strong>Width:</strong> {width}</p>
                                                            <p><strong>Area:</strong> {area}</p>
                                                        </li>
                                                    );
                                                })
                                            ) : (
                                                <li>No claims</li>
                                            )}
                                        </ul>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p>Loading...</p>
                )}
            </div>
        </div>
    );
};

export default PlayerClaimsReportComponent;