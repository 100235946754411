import React, { useState } from 'react';
import WaxWalletLoginComponent from './WaxWalletLoginComponent';
import ServerStatusComponent from './ServerStatusComponent';
import WhitelistComponent from './WhitelistComponent';
import VideoComponent from './VideoComponent'; // Import the VideoComponent

import { CsrfTokenProvider } from './CsrfTokenContext';

function HomePage({
    setMinecraftUUID,
    setUser,
    setCsrfToken,
    setMinecraftName,
    setUserData,
    setError,
    user,
    csrfToken,
    minecraftName,
    minecraftUUID,
    userData,
    error
}) {

    return (
        <CsrfTokenProvider>
            <div>
                <WaxWalletLoginComponent setMinecraftUUID={setMinecraftUUID}
                    setUser={setUser}
                    setCsrfToken={setCsrfToken}
                    setMinecraftName={setMinecraftName}
                    setUserData={setUserData}
                    setError={setError}
                    user={user}
                    csrfToken={csrfToken}
                    minecraftName={minecraftName}
                    userData={userData}
                    error={error} />
                <ServerStatusComponent />
                <WhitelistComponent />
                <VideoComponent />

                {/* <PlayerStatsComponent />
                <PlayerTotalBlocksComponent />
                {user && <PlayerPersonalStatsComponent uuid={minecraftUUID} user={user} />} */}


            </div>

        </CsrfTokenProvider>

    );
}

export default HomePage;