import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './NavigationMenuComponent.css'; // Import the CSS file for styling

const NavigationMenuComponent = ({ user }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="nav-menu">
            <i className="fa-solid fa-bars" id="menu" onClick={toggleMenu}></i>
            <div className={`menu-content ${isOpen ? 'open' : ''}`}>
                <ul>
                    <li><Link to="/" onClick={toggleMenu}>Home</Link></li>
                    <li><Link to="/stats" onClick={toggleMenu}>Server Stats</Link></li>
                    <li><Link to="/claims" onClick={toggleMenu}>Player Claims</Link></li>
                    {/* {user && <li><Link to="/mystats" onClick={toggleMenu}>MyStats</Link></li>} */}
                </ul>
            </div>
        </div>
    );
};

export default NavigationMenuComponent;