import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

const CsrfTokenContext = createContext();

export const CsrfTokenProvider = ({ children }) => {
    const [csrfToken, setCsrfToken] = useState('');

    useEffect(() => {
        // Fetch CSRF token once
        const fetchCsrfToken = async () => {
            try {
                //console.log('Fetching CSRF token...');
                const response = await axios.get('/api/csrf-token');
                setCsrfToken(response.data.csrfToken);
            } catch (error) {
                console.error('Error fetching CSRF token:', error);
            }
        };

        fetchCsrfToken();
    }, []);

    return (
        <CsrfTokenContext.Provider value={csrfToken}>
            {children}
        </CsrfTokenContext.Provider>
    );
};

export const useCsrfToken = () => {
    const context = React.useContext(CsrfTokenContext);
    if (context === undefined) {
        throw new Error('useCsrfToken must be used within a CsrfTokenProvider');
    }
    return context;
};